import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  catchError,
  EMPTY,
  filter,
  finalize,
  Observable,
  switchMap,
  throwError,
} from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}
  refreshUrl = '/auth/refresh';
  private refreshingToken = false; // Flag to track if token refresh is in progres

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const requestWithToken = this.addToken(request);

    return next
      .handle(requestWithToken)
      .pipe(
        catchError((error: HttpErrorResponse) =>
          this.errorAuthHandle(error, request, next)
        )
      );
  }

  private addToken(request: HttpRequest<any>): HttpRequest<any> {
    const accessToken = localStorage.getItem('currentUser');
    let value;
    if (accessToken) {
      value = JSON.parse(accessToken);
    } else {
      value = null;
    }
    if (value && !request.url.includes(this.refreshUrl)) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${value.accessToken}`,
        },
      });
    }
    return request;
  }

  private errorAuthHandle(
    error: HttpErrorResponse,
    request: HttpRequest<unknown>,
    next: HttpHandler
  ) {
    if (
      (error.status === 401 || error.error.statusCode === 401) &&
      error.error.statusMessage === 'Unauthorized'
    ) {
      return this.refreshTokenMethod(request, next);
    } else if (
      error.status === 401 &&
      error.error.statusMessage === 'AccessToken is invalid.'
    ) {
      this.forceLogout();

      return EMPTY;
    } else if (
      ((error.status === 403 || error.error.statusCode === 403) &&
        error.error.statusMessage === 'Forbidden resource') ||
      ((error.status === 403 || error.error.statusCode === 403) &&
        error.error.statusMessage === 'Forbidden')
    ) {
      this.forceLogout();

      return EMPTY;
    } else {
      return throwError((): any => error);
    }
  }

  private refreshTokenMethod(request: HttpRequest<unknown>, next: HttpHandler) {
    if (!this.refreshingToken) {
      this.refreshingToken = true; // Set flag to indicate token refresh is in progress
      this.authService.notifyRefreshTokenUpdated(null);

      return this.authService.RefreshToken().pipe(
        switchMap((res: any) => {
          const currentUser = localStorage.getItem('currentUser');

          const value = JSON.parse(currentUser!);

          value.accessToken = res.data.accessToken;
          value.refreshToken = res.data.refreshToken;
          window.localStorage.setItem('currentUser', JSON.stringify(value));
          this.authService.notifyRefreshTokenUpdated(res.data.accessToken);

          request = request.clone({
            setHeaders: { Authorization: 'Bearer ' + res.data.accessToken },
          });

          return next.handle(request);
        }),
        catchError((error) => {
          // Reset the flag if there's an error during token refresh

          this.refreshingToken = false;

          if (
            ((error.status === 401 || error.error.statusCode === 401) &&
              error.error.statusMessage === 'Unauthorized') ||
            ((error.status === 403 || error.error.statusCode === 403) &&
              error.error.statusMessage === 'Access Denied') ||
            ((error.status === 403 || error.error.statusCode === 403) &&
              error.error.statusMessage === 'Forbidden resource') ||
            ((error.status === 403 || error.error.statusCode === 403) &&
              error.error.statusMessage === 'Forbidden')
          ) {
            this.forceLogout();

            return EMPTY;
          } else {
            return throwError(() => error);
          }
        }),
        finalize(() => {
          this.refreshingToken = false;
        })
      );
    } else {
      // If a refresh token request is already in progress, wait for it to complete

      return this.authService.refreshTokenObservable.pipe(
        filter((token) => {
          return token !== null;
        }),
        switchMap((): Observable<HttpEvent<any>> => {
          return next
            .handle(this.addToken(request))
            .pipe(
              catchError((error: HttpErrorResponse) =>
                this.errorAuthHandle(error, request, next)
              )
            );
        })
      );
    }
  }

  forceLogout() {
    // function force user to go to login
    localStorage.removeItem('currentUser');
    window.location.replace('/');
  }
}
