import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
export interface IVerify {
  isVerify: boolean;
  isOnQueue: boolean;
}

@Injectable()
export class PermissionsService {
  private isVerifySubject!: BehaviorSubject<IVerify>;

  constructor(private router: Router) {
    const data: IVerify = { isVerify: false, isOnQueue: false };
    this.isVerifySubject = new BehaviorSubject<IVerify>(data);
  }

  get getVerified() {
    return this.isVerifySubject.value;
  }

  verifySuccess(isVerify: IVerify) {
    this.isVerifySubject.next(isVerify);
  }

  canActivate(): boolean {
    if (this.isVerifySubject.value.isVerify) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}

export const verfiedGuard: CanActivateFn = (route, state) => {
  return inject(PermissionsService).canActivate();
};
