import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  catchError,
  Observable,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { IReponseErr, IResponse } from '../shared/interfaces/utils.model';
import { IAdminAuthData } from '../shared/interfaces/user.model';
import { UserService } from '../admin/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = environment.apiUrl; // Replace with your actual API URL
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<IAdminAuthData | null>;
  private refreshTokenSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private userService: UserService
  ) {
    this.currentUserSubject = new BehaviorSubject<IAdminAuthData | null>(
      JSON.parse(localStorage.getItem('currentUser')!)
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    return this.http
      .post<any>(`${this.apiUrl}auth/signin`, { email, password })
      .pipe(
        switchMap((user: IResponse) => {
          // Once login is successful, call getProfile
          const { data } = user;
          localStorage.setItem('currentUser', JSON.stringify(data));
          this.currentUserSubject.next(data);

          // Call getProfile as a function within switchMap
          return this.userService.getProfile();
        }),
        tap((profile: IResponse) => {
          // Store or handle profile data here if needed
          const { data } = profile;
          localStorage.setItem('profile', JSON.stringify(data));
          this.userService.updateProfileData(data);
        }),
        catchError(this.handleError)
      );
  }

  logout() {
    // remove user from local storage to log user out
    this.http
      .get<any>(`${this.apiUrl}auth/logout`)
      .pipe(
        tap(() => {
          localStorage.removeItem('currentUser');
          this.currentUserSubject.next(null);
        }),
        catchError(this.handleError)
      )
      .subscribe({
        next: (data: IResponse) => {},
        error: (err: IReponseErr) => {
          console.log(err);
        },
        complete: () => {
          this.router.navigate(['/admin']);
        },
      });
  }

  // Function to notify subscribers when the refresh token is updated
  public notifyRefreshTokenUpdated(token: string | null): void {
    this.refreshTokenSubject.next(token);
  }

  // Getter for the observable to subscribe to refresh token updates
  public get refreshTokenObservable(): Observable<any> {
    return this.refreshTokenSubject.asObservable();
  }

  RefreshToken(): Observable<any> {
    const currentUser = localStorage.getItem('currentUser');
    let refreshToken;
    if (currentUser) {
      const parseData = JSON.parse(currentUser);
      refreshToken = parseData.refreshToken ? parseData.refreshToken : null;
    } else {
      refreshToken = null;
    }

    let header = new HttpHeaders({
      Authorization: 'Bearer ' + refreshToken,
    });
    return this.http.get<any>(`${this.apiUrl}auth/refresh`, {
      headers: header,
    });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      console.error('service::error ', error.error);
    }
    let errorMessage = '';
    if (Array.isArray(error.error.statusMessage)) {
      errorMessage = error.error.statusMessage.toString();
    } else {
      errorMessage = error.error.statusMessage;
    }
    // console.error('offer.service::errorMessage:: ', errorMessage);
    return throwError(() => error.error);
  }
}
