<div class="flex flex-col w-screen min-h-screen">
  <div class="flex-grow flex flex-col items-center justify-center p-10">
    <h1 class="text-4xl font-medium text-center text-main-blue">
      ไม่พบหน้านี้
    </h1>
    <p class="text-xl opacity-50 mt-3 text-center">
      ขออภัย ไม่พบหน้าที่คุณกำลังค้นหา
    </p>
    <div class="mt-10">
      <button
        routerLink="/"
        class="flex items-center justify-center gap-3 text-white bg-main-color border-2 border-main-color px-7 py-2 text-base w-full md:text-lg disabled:hover:scale-100 hover:bg-dark-main-color hover:border-dark-main-color active:scale-105"
      >
        <span class="pi text-xl pi-home"></span>
        <span class="font-light">กลับสู่หน้าหลัก</span>
      </button>
    </div>
  </div>
  <footer
    class="bg-gradient-to-r from-main-color to-dark-main-color text-white p-4"
  >
    <div
      class="container sm:mx-auto flex flex-col justify-between md:flex-row w-4/5"
    >
      <div
        class="flex items-start sm:justify-center md:justify-start mb-4 md:mb-0 md:pt-6"
      >
        <a class="cursor-pointer" routerLink="/"
          ><div class="w-48">
            <img
              class="w-full"
              src="../assets/logo.png"
              alt="logo image"
            /></div
        ></a>
      </div>
      <div class="flex justify-end max-sm:flex-col">
        <div
          class="flex flex-col sm:items-center md:items-start sm:mx-auto mb-4 md:mb-0 sm:p-6"
        >
          <span class="mb-2 text-xl font-taviraj"> ข้อมูลเพิ่มเติม </span>
          <ul class="flex flex-col gap-2">
            <li
              routerLink="/policy"
              class="cursor-pointer hover:underline opacity-80"
            >
              <a>นโยบายความเป็นส่วนตัว</a>
            </li>
          </ul>
        </div>
        <div
          class="flex flex-col sm:items-center md:items-start sm:mx-auto sm:p-6"
        >
          <span class="mb-2 text-xl font-medium font-taviraj">
            Contact Us
          </span>
          <ul class="flex flex-col gap-2 opacity-80">
            <li class="flex gap-2 items-center cursor-pointer hover:underline">
              <span class="pi pi-mobile"></span> <a>โทร: 0632021933</a>
            </li>
            <li class="flex gap-2 items-center cursor-pointer hover:underline">
              <span class="pi pi-envelope"></span>
              <a>admin{{ "@" }}vathanagul.com</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="container sm:mx-auto flex flex-col sm:justify-between md:flex-row w-4/5 max-sm:mt-9"
    >
      <span>© 2024 All rights reserved.</span>
    </div>
  </footer>
</div>
